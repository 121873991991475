<template>
  <div class="container">
    <memlist-modal size="lg" ref="edit-form" :visible="show_modal" @close="show_modal = false" hide-footer>
      <div>
        <h4>Körning {{ job.id }}</h4>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              :title="$t('JOB.CREATED_AT')" 
              id="created_at" 
              name="created_at"
              layout="vertical"
              v-model="job.created_at" 
              :disabled="true"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              :title="$t('JOB.FINISHED_AT')" 
              id="finished_at" 
              name="finished_at"
              layout="vertical"
              v-model="job.finished_at" 
              :disabled="true"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              :title="$t('JOB.TYPE')" 
              id="type" 
              name="type"
              layout="vertical"
              v-model="type" 
              :disabled="true"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              :title="$t('JOB.STATUS')" 
              id="status" 
              name="status"
              layout="vertical"
              v-model="status" 
              :disabled="true"
            />
          </div>
        </div>

        <div class="ml-row mt-4">
          <div class="ml-col">
            <memlist-text-input 
              :title="$t('JOB.RESULT')" 
              id="result" 
              name="result"
              layout="vertical"
              v-model="result" 
              :disabled="true"
            />
          </div>
        </div>

      </div>
      
      <RightModalSaveAndCloseButtons
        class="mt-4"
        :text="$t('COMMON.SAVE')"
        :spin="saving"  
        @clicked="save_job" 
        @close="show_modal = false" 
        ref="saveButton" 
      />
    </memlist-modal>
  </div>
</template>

<script>


import { get_domain_url } from '@/core/services/fileDownload';
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'JobEditorModal',
  components: {
    
  },
  computed: {
    type() {
      return this.job && this.job.type ? this.$t('JOB.TYPES.' + this.job.type.toUpperCase()) : '-';
    },
    status() {
      return this.job && this.job.status ? this.$t('JOB.STATUSES.' + this.job.status.toUpperCase()) : '-';
    },
    result() {
      return this.job && this.job.result ? this.$t('JOB.RESULTS.' + this.job.result.toUpperCase()) : '-';
    },
    ...mapGetters(['currentCompanyId', 'customer']),
  
  },
  props: {
    job_id: {
      type: Number,
      required: false,
      default: null,
    },
  },
  watch: {
    job_id: {
      handler(newVal) {

        if (this.job_id) {
          this.fetch_job(this.job_id);
        }
        else {
          this.job = {
            
          };
        }
      },
      deep: true,
      immediate: true,
    },
  },

  data() {
    return {
      show_modal: false,
      saving: false,
      job: {
        
      }
    };
  },
  methods: {
    show() {
      this.show_modal = true;
    },
    hide() {
      this.show_modal = false;
    },

    save_job() {
      this.$refs['saveButton'].stop();
      this.hide();
    },

    async fetch_job(id) {
      const res = await axios.get(`/job/${id}`);

      if (res.status === 200) {
        this.job = res.data;
      }
    },

  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
@import "@/assets/sass/components/forms/_memlist_tabs.scss";

.container {
  padding: 20px;
}
.checkbox-group {
  display: flex;
  gap: 10px;
}
</style>
